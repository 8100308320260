import React, { Component } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Input,
  Container,
  Label,
  Row,
  Col,
} from "reactstrap";
import ci from "../../../images/cutis_new.jpeg";

const PrintComponent = React.forwardRef(({ branch, data }, ref) => {
  console.log(data);
  return (
    <div ref={ref}>
      <style>
        {`
        @media print {
          @page {
            size: auto; 
            margin: 10mm;
          }
          .page-break {
            page-break-before: always;
          }
        }
      `}
      </style>
      <Container>
        <Card style={{ width: "100%", fontSize: "11px" }} className="mb-1 mt-1">
          <CardHeader className="d-flex justify-content-start mt-1 px-2 bg-white">
            <Row>
              <Col md="5" sm="6">
                <img
                  src={ci}
                  alt="image"
                  style={{
                    marginLeft: "0px",
                    overflow: "hidden",
                    height: "auto",
                    width: "200px",
                    // opacity: "60%",
                  }}
                />
              </Col>
              <Col md={6} sm={9}>
                <div className="ps-3">
                  {
                    //  <span style={{ fontSize: "12px" }}>
                    //    <b>{data.address.first_line}</b>
                    //  </span>
                    //  <br />
                    //  <span style={{ fontSize: "11px" }}>
                    //    {data.address.second_line}
                    //  </span>
                    //  <br />
                    //  <span style={{ fontSize: "11px" }}>
                    //    {data.address.third_line}
                    //  </span>
                    //  <br />
                    //  <span style={{ fontSize: "11px" }}>
                    //    {data.address.fourth_line}
                    //  </span>
                  }
                </div>
              </Col>
            </Row>
          </CardHeader>

          <div className="d-flex justify-content-center px-2 mt-3">
            <h6 style={{ fontSize: "12px", fontWeight: "bold" }}>
              Consultation Record
            </h6>{" "}
          </div>
          <div
            className="d-flex justify-content-between pb-2 px-2"
            style={{ fontSize: "12px" }}
          >
            <div className="ms-2 px-2">
              Patient Name: {data?.selectedCm?.fname} {data?.selectedCm?.sname}{" "}
              <br />
              Contact No: {data?.selectedCm?.contactno}
              <br />
              MRN: {data?.selectedCm?.mrn}
            </div>
            <div className="me-2 px-2">
              Doctor: {data?.username}
              <br />
              Consultation on:{" "}
              {`${new Date(data?.date).getDate()} / ${
                new Date(data?.date).getMonth() + 1
              } /  ${new Date(data?.date).getFullYear()} `}{" "}
              {new Date(data?.date).toLocaleTimeString("en-US")}
            </div>
          </div>
          <CardBody className="px-2 mx-3">
            <Label for="diagnosis"> complaints/History:</Label>
            <Input
              type="textarea"
              name="complaintsnHistory"
              id="complaintsnHistory"
              rows="5"
              value={data?.complaintsnHistory}
              readOnly
            />
            <Label for="diagnosis"> Diagnosis:</Label>
            <Input
              type="textarea"
              name="text"
              id="diagnosis"
              rows="5"
              value={data?.diagnosis}
              readOnly
            />
            <Label for="prescription"> Prescriptions:</Label>
            <Input
              type="textarea"
              name="text"
              rows="5"
              id="prescription"
              value={data?.prescription}
              readOnly
            />
            <Label for="specialAdvice"> Special advice:</Label>
            <Input
              type="textarea"
              name="text"
              rows="5"
              id="specialadvice"
              value={"sds"}
              readOnly
            />
          </CardBody>
        </Card>
      </Container>
    </div>
  );
});

export default PrintComponent;
