import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Button,
  CardBody,
  Table,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Tooltip,
} from "reactstrap";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Rupees } from "../../../../images/currency-rupee.svg";
import { CornerDownLeft, Eye, Trash } from "react-feather";
import { getCustomers, searchCustomers } from "../../../../Data/Customer/api";
import { getInventoryItems } from "../../../../redux/inventory";
import PdfGenerator from "./PdfGenerator";
const SalesInvoices = ({}) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.authentication);
  const { userData } = auth;
  const itemsList = useSelector((state) => state.itemsList);
  const { itemsData } = itemsList;
  const navigate = useNavigate();
  const [filterType, setFilterType] = useState(
    JSON.parse(sessionStorage.getItem("filterType")) || "date"
  );
  const [selectedDate, setSelectedDate] = useState(
    JSON.parse(sessionStorage.getItem("selectedDate")) || ""
  );
  const [fromDate, setFromDate] = useState(
    JSON.parse(sessionStorage.getItem("fromDate")) || ""
  );
  const [toDate, setToDate] = useState(
    JSON.parse(sessionStorage.getItem("toDate")) || ""
  );
  const [dateError, setDateError] = useState("");
  const [fromDateError, setFromDateError] = useState("");
  const [toDateError, setToDateError] = useState("");
  const [filteredInvoices, setFilteredInvoices] = useState(
    JSON.parse(sessionStorage.getItem("filteredInvoices")) || []
  );
  const [totalFilteredInvoices, setTotalFilteredInvoices] = useState(
    JSON.parse(sessionStorage.getItem("totalFilteredInvoices")) || 0
  );
  const [totalPaidFilteredInvoices, setTotalPaidFilteredInvoices] = useState(
    JSON.parse(sessionStorage.getItem("totalPaidInvoices")) || ""
  );
  const [totalExcelFilteredInvoices, setTotalExcelFilteredInvoices] =
    useState("");

  const [showSpinner, setShowSpinner] = useState(false);
  const [showBillLoading, setShowBillLoading] = useState(false);
  const [showReturnLoading, setShowReturnLoading] = useState(false);
  const [returnSpinnerId, setReturnSpinnerId] = useState(false);
  const [spinnerId, setSpinnerId] = useState("");
  const [patientSearch, setPatientSearch] = useState("");
  const [mrn, setMrn] = useState("");
  const [modal, setModal] = useState(false);
  const [patientList, setPatientList] = useState("");
  const toggle = () => setModal(!modal);

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleToolTip = (id) => {
    setTooltipOpen((prev) => ({
      ...prev,
      [id]: !prev[id], // Toggle only the specific tooltip
    }));
  };
  // Validate date inputs
  const validateDate = (date) => {
    const pattern = /^\d{4}-\d{2}-\d{2}$/;
    return pattern.test(date);
  };

  const validate = () => {
    let isValid = true;

    if (!validateDate(fromDate) && !validateDate(selectedDate)) {
      setFromDateError("Please enter a valid From Date (YYYY-MM-DD).");

      isValid = false;
    } else {
      setFromDateError("");
      setDateError("");
    }

    if (!validateDate(toDate) && !validateDate(selectedDate)) {
      setToDateError("Please enter a valid To Date (YYYY-MM-DD).");

      isValid = false;
    } else {
      setToDateError("");
    }
    return isValid;
  };

  const clearError = () => {
    setDateError("");
    setFromDateError("");
    setToDateError("");
  };

  const qtyStockCheck = (productId) => {
    const product = itemsData.find((prod) => prod.productId === productId);
    return product.quantity;
  };

  useEffect(() => {
    setTotalExcelFilteredInvoices(totalFilteredInvoices);
  }, [totalFilteredInvoices]);

  useEffect(() => {
    if (userData.token) {
      dispatch(
        getInventoryItems({ token: userData.token, branch: userData.branch })
      );
    }
  }, [userData.token]);

  useEffect(() => {
    if (selectedDate || (fromDate && toDate)) {
      filterHandler();
    }
  }, []);

  // useEffect(() => {
  //   console.log(selectedDate);
  //   if (filterType === "date") {
  //     const today = new Date();
  //     const formattedDate = today.toISOString().split("T")[0];
  //     setSelectedDate(formattedDate);
  //     setFromDate("");
  //     setToDate("");
  //   } else if (filterType === "") {
  //     setSelectedDate("");
  //   }
  // }, [filterType]);

  const filterTypeHandler = (e) => {
    setFilterType(e.target.value);

    const type = e.target.value;
    if (type === "") {
      setSelectedDate("");
    }
    if (type === "date") {
      setFromDate("");
      setToDate("");
    }
  };
  //  useEffect(() => {
  //    if (editData) {
  //      // setEditMode(true);
  //      const invoiceDate = new Date(editData.invoice_date); // Convert the invoice date to a Date object
  //      const currentDate = new Date(); // Get the current date
  //
  //      // Calculate the difference in time (in milliseconds)
  //      const timeDifference = currentDate - invoiceDate;
  //
  //      // Check if the time difference is less than 24 hours (24 * 60 * 60 * 1000 milliseconds)
  //      if (timeDifference < 24 * 60 * 60 * 1000) {
  //        setEditMode(true);
  //      }
  //    }
  //  }, [editData]);

  useEffect(() => {
    if (patientSearch && patientSearch.length > 9) {
      searchCustomers(
        userData.token,
        patientSearch,
        userData.branch,
        navigate
      ).then((res) => {
        if (res.length === 1) {
          const [{ mrn }] = res;

          setMrn(mrn);
        } else {
          setPatientList(res);
          toggle();
        }
      });
    }
  }, [patientSearch]);

  const filterHandler = () => {
    setTotalFilteredInvoices("");
    setShowSpinner(true);
    setFilteredInvoices("");

    if (validate()) {
      let newSelectedDate;
      let date;
      let fromDateWithoutHours;
      let toDateWithoutHours;
      if (selectedDate) {
        newSelectedDate = new Date(selectedDate);
        date = new Date(newSelectedDate.setHours(0, 0, 0, 0)).toISOString();
      }

      if (fromDate) {
        newSelectedDate = new Date(fromDate);
        fromDateWithoutHours = new Date(
          newSelectedDate.setHours(0, 0, 0, 0)
        ).toISOString();
      }
      if (toDate) {
        newSelectedDate = new Date(toDate);
        toDateWithoutHours = new Date(
          newSelectedDate.setHours(0, 0, 0, 0)
        ).toISOString();
      }
      if (date || fromDate || toDate)
        axios
          .get(`${process.env.REACT_APP_CRI_API}get-invoices`, {
            params: {
              branch: userData.branch,
              date: date,
              fromDate: fromDateWithoutHours,
              toDate: toDateWithoutHours,
              mrn,
            },
            headers: { Authorization: `Bearer ${userData.token}` },
            "Content-Type": "application/json",
          })
          .then((res) => {
            setShowSpinner(false);

            setFilteredInvoices(res.data);
            sessionStorage.setItem(
              "filteredInvoices",
              JSON.stringify(res.data)
            );
            sessionStorage.setItem("filterType", JSON.stringify(filterType));
            sessionStorage.setItem(
              "selectedDate",
              JSON.stringify(selectedDate)
            );
            sessionStorage.setItem("fromDate", JSON.stringify(fromDate));
            sessionStorage.setItem("toDate", JSON.stringify(toDate));

            if (res.data) {
              if (res.data.length === 0) {
                alert("No Data");
              }
              setTotalFilteredInvoices(() => {
                const totalAmountPaidArray = res.data.map(
                  (item) => item && item.total_amount
                );
                const totalAmountPaid = totalAmountPaidArray.reduce(
                  (total, amount) => parseFloat(total) + parseFloat(amount),
                  0
                );
                // console.log(totalAmountPaid);
                sessionStorage.setItem(
                  "totalFilteredInvoices",
                  JSON.stringify(totalAmountPaid)
                );
                return totalAmountPaid;
              });

              setTotalPaidFilteredInvoices(() => {
                const totalAmountPaidArray = res.data.map(
                  (item) => item && item.total_amount_paid
                );
                const totalAmountPaid = totalAmountPaidArray.reduce(
                  (total, amount) => parseFloat(total) + parseFloat(amount),
                  0
                );
                // console.log(totalAmountPaid);
                sessionStorage.setItem(
                  "totalPaidInvoices",
                  JSON.stringify(totalAmountPaid)
                );
                return totalAmountPaid;
              });
            }
          })

          .catch((err) => {
            setShowSpinner(false);
            console.log(err);
            if (
              (err.response &&
                err.response.data &&
                err.response.data.err.name === "TokenExpiredError") ||
              err.response.data.message === "Unauthorized"
            ) {
              navigate("/");
              sessionStorage.removeItem("token");
              sessionStorage.removeItem("srchData");
              sessionStorage.removeItem("prescriptionHistory");
              sessionStorage.removeItem("rights");
              navigate("/cri-crm");
              return err;
            }
            console.log(err);
            return err;
          });
    }
  };
  const convertTimestampToIST = (dateinv) => {
    const replacedate = dateinv.replace("INV-", "");
    const timestamp = Number(replacedate);
    // console.log(replacedate);
    // setDisplayTime(() => convertTimestampToIST(new Date(timestamp)));
    // Convert milliseconds to seconds
    const date = new Date(timestamp);
    //console.log(date);
    // Options for formatting the date and time
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true, // Use 12-hour format
      timeZone: "Asia/Kolkata", // Indian Standard Time (IST)
    };

    // Format the date and time
    return date.toLocaleString("en-IN", options);
  };

  // const replacedate = data.invoiceSheetData.invoice_id.replace("INV-", "");
  // const timestamp = Number(replacedate);
  // console.log(replacedate);
  // setDisplayTime(() => convertTimestampToIST(new Date(timestamp)));

  const searchCustomerHandler = (invoice, mode) => {
    // searchCustomerHandler(item, "Return Mode");
    setShowBillLoading(true);
    setShowReturnLoading(true);
    //token, searchInput, branch, navigate
    searchCustomers(
      userData.token,
      invoice.mrn,
      userData.branch,
      navigate
    ).then((res) => {
      if (res.length) {
        const [
          { fname, sname = "", sex = "", contactno, nationality, doctorName },
        ] = res;

        navigate("/billentry", {
          //replace: true,
          state: {
            otData: {
              ...invoice,
              fname: `${fname} ${sname}`,
              sex: sex,
              contactno: contactno,
              nationality,
              doctorName,
            },
            invoiceSheetData: invoice,
            mode,
          },
        });
        setSpinnerId("");
        setReturnSpinnerId("");
        setShowBillLoading(false);
        setShowReturnLoading(false);
      }
    });
  };

  // const getCustomerName = (mrn) => {
  //   let pname = "";

  //   getCustomers(userData.token, userData.branch, mrn, "", "", navigate).then(
  //     (res) => {
  //       console.log(res);
  //       const { fname, sname } = res[0];
  //       pname = `${fname} ${sname}`;
  //       console.log(pname);
  //     }
  //   );

  //   return pname;
  // };

  const updateInventory = (productId, qty) => {
    const postDataInventory = {
      quantity: qty,
    };
    axios
      .post(
        `${process.env.REACT_APP_CRI_API}update-inventory-qty`,
        postDataInventory,
        {
          params: {
            branch: userData.branch,
            productId: productId,
          },
          headers: {
            Authorization: `Bearer ${userData.token}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          console.log("Inventory Updated");
        }
      })
      .catch((err) => console.log(err));
  };

  // For adding back stock before deleting invoice
  const stockHandler = (item) => {
    const invoiceId = prompt("Please enter the invoice Id to confirm delete");
    if (
      invoiceId &&
      invoiceId.toLocaleLowerCase() === item.invoice_id.toLocaleLowerCase()
    ) {
      const consolidatedQuantities = {};

      // Consolidate quantities for the same productId
      item.invoice_sheet.forEach((row, rowIndex) => {
        const { productId, qty = 1, stockQty } = row[`Row${rowIndex + 1}`];

        if (productId) {
          consolidatedQuantities[productId] = {
            totalQty:
              Number(consolidatedQuantities[productId]?.totalQty || 0) +
              Number(qty),
            stockQty: stockQty,
          };
          // console.log(consolidatedQuantities);
        }
      });

      // Prepare promises for updating inventory
      const promises = Object.entries(consolidatedQuantities).map(
        ([productId, { totalQty }]) => {
          const qtyStock = qtyStockCheck(productId);
          const updtQty = Number(qtyStock) + Number(totalQty);

          return updateInventory(productId, updtQty);
        }
      );

      // Execute promises and handle results
      Promise.all(promises)
        .then(() => {
          // console.log("All inventory updated successfully");
          // Perform any other actions (e.g., submit the invoice)
          deleteHandler(item);
        })
        .catch((error) => {
          console.error("Error updating inventory:", error);
        });
    }
  };

  const deleteHandler = (item) => {
    axios
      .delete(`${process.env.REACT_APP_CRI_API}delete-invoice`, {
        params: { id: item._id, branch: userData.branch },
        headers: {
          Authorization: `Bearer ${userData.token}`,
        },
      })
      .then((res) => {
        if (res) {
          filterHandler();
        }
      })
      .catch((err) => {
        if (
          (err.response &&
            err.response.data &&
            err.response.data.err &&
            err.response.data.err.name === "TokenExpiredError") ||
          err.response.data.message === "Unauthorized"
        ) {
          navigate("/");
          sessionStorage.removeItem("token");
          sessionStorage.removeItem("srchData");
          sessionStorage.removeItem("prescriptionHistory");
          sessionStorage.removeItem("rights");
          navigate("/cri-crm");
          return err;
        }
      });
  };

  return (
    <>
      <Row>
        <Col className="text-center" md={12}>
          <Card className="mt-2 pt-4 m-2">
            <CardTitle>
              <b>Sales Invoices</b>

              <Row>
                <Col md="1" className="pb-4">
                  <button
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    Back
                  </button>
                </Col>
              </Row>
              <div className="d-flex ps-3">
                {" "}
                <FormGroup className="me-3 ps-2">
                  <Label for="filterType">Filter Type:</Label>
                  <Input
                    type="select"
                    name="filterType"
                    id="filterType"
                    value={filterType}
                    onChange={filterTypeHandler}
                  >
                    <option value="">Select</option>
                    <option value="yearly">Yearly</option>
                    <option value="monthly">Monthly</option>
                    <option value="date">Date</option>
                  </Input>
                </FormGroup>
                {filterType === "date" && (
                  <FormGroup>
                    <Label for="selectedDate">Select Date:</Label>
                    <Input
                      type="date"
                      name="selectedDate"
                      id="selectedDate"
                      value={selectedDate}
                      onChange={(e) => {
                        clearError();
                        setSelectedDate(e.target.value);
                        setFromDate("");
                        setToDate("");
                      }}
                      invalid={dateError !== ""}
                    />
                  </FormGroup>
                )}
                {filterType !== "date" && (
                  <>
                    <FormGroup>
                      <Label for="fromDate">From Date:</Label>
                      <Input
                        type="date"
                        name="fromDate"
                        id="fromDate"
                        value={fromDate}
                        onChange={(e) => {
                          setFromDate(e.target.value);
                          clearError();
                        }}
                        invalid={fromDateError !== ""}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="toDate">To Date:</Label>
                      <Input
                        type="date"
                        name="toDate"
                        id="toDate"
                        value={toDate}
                        onChange={(e) => {
                          setToDate(e.target.value);
                          clearError();
                        }}
                        invalid={toDateError !== ""}
                      />
                    </FormGroup>
                  </>
                )}
                <FormGroup className="ms-3">
                  <Label for="patientSearch">Patient Mobile:</Label>
                  <Input
                    type="text"
                    name="patientSearch"
                    id="patientSearch"
                    value={patientSearch}
                    onChange={(e) => {
                      setPatientList("");
                      setPatientSearch(e.target.value);
                      setMrn("");
                      clearError();
                    }}
                  />
                </FormGroup>
                <button
                  onClick={filterHandler}
                  className=" ms-3"
                  style={{
                    width: "auto",
                    height: "40px",
                    borderRadius: "10px",
                    marginTop: "30px",
                  }}
                >
                  Search
                </button>
                <div>
                  {filteredInvoices && (
                    <PdfGenerator invoices={filteredInvoices} />
                  )}
                </div>
              </div>

              {showSpinner && <Spinner color="primary" />}
            </CardTitle>
            <CardBody>
              {filteredInvoices && filteredInvoices.length > 0 && (
                <>
                  <Row className="p-2">
                    <Col md={2}>
                      <Card className="p-2">
                        <div>Total Sales </div>
                        <div className=" d-flex justify-content-center ">
                          <div style={{ width: "20px" }}>
                            {" "}
                            <Rupees
                              style={{
                                maxWidth: "13px",
                                maxHeight: "13px",
                              }}
                            />
                          </div>
                          <div>{totalExcelFilteredInvoices}</div>
                        </div>
                      </Card>
                    </Col>

                    <Col md={2}>
                      <Card className="p-2">
                        {" "}
                        Total Paid
                        <div className=" d-flex justify-content-center ">
                          <div style={{ width: "20px" }}>
                            {" "}
                            <Rupees
                              style={{
                                maxWidth: "13px",
                                maxHeight: "13px",
                              }}
                            />
                          </div>
                          <div>
                            {" "}
                            {totalPaidFilteredInvoices
                              ? totalPaidFilteredInvoices
                              : ""}
                          </div>
                        </div>
                      </Card>
                    </Col>
                    <Col md={2}>
                      <Card className="p-2">Pending</Card>
                    </Col>
                  </Row>
                  <Table color="secondary" bordered className="m-2 mb-5">
                    <thead>
                      <tr>
                        <th style={{ width: "10%" }}>Invoice Date</th>
                        <th style={{ width: "10%" }}>Invoice ID</th>
                        <th style={{ width: "10%" }}>MRN/Name</th>
                        <th style={{ width: "10%" }}>Amount</th>
                        <th style={{ width: "10%" }}>Amount Paid</th>
                        <th style={{ width: "10%" }}>Payment Mode</th>
                        <th>Billed By</th>
                        <th style={{ width: "5%" }}>Return</th>
                        <th style={{ width: "5%" }}>View/Edit</th>
                        <th style={{ width: "5%" }}>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredInvoices.map((item, id) => {
                        return (
                          <tr key={id}>
                            <td>
                              {
                                // new Date(item.invoice_date).toLocaleDateString()
                                convertTimestampToIST(item.invoice_id)
                              }
                            </td>
                            <td>{item.invoice_id}</td>
                            <td>{item.patientName || item.mrn}</td>
                            <td>{item.total_amount}</td>
                            <td>{item.total_amount_paid}</td>

                            <td>
                              {Array.isArray(item.payment_mode)
                                ? item.payment_mode
                                    .map((pm) => `${pm.mode}: ${pm.amount}`)
                                    .join(", ")
                                : item.payment_mode}
                            </td>
                            <td style={{ width: "10%" }} id={`Tooltip-${id}`}>
                              {item.createdBy}
                              <Tooltip
                                placement="top"
                                isOpen={tooltipOpen[`Tooltip-${id}`] || false} // Tooltip specific to this row
                                target={`Tooltip-${id}`}
                                toggle={() => toggleToolTip(`Tooltip-${id}`)}
                              >
                                {item.remarks || "No remarks available"}{" "}
                                {/* Tooltip content */}
                              </Tooltip>
                            </td>
                            <td>
                              {showReturnLoading && returnSpinnerId === id && (
                                <Spinner />
                              )}
                              {returnSpinnerId !== id && (
                                <CornerDownLeft
                                  color="blue"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setReturnSpinnerId(id);
                                    searchCustomerHandler(item, "Return Mode");
                                    // edit(item, toggle, "Return Mode");
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {showBillLoading && spinnerId === id && (
                                <Spinner />
                              )}
                              {spinnerId !== id && (
                                <Eye
                                  color="green"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setSpinnerId(id);
                                    searchCustomerHandler(item);
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              <Trash
                                color="red"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  // setEditMode(true);

                                  const invoiceDate = new Date(
                                    item.invoice_date
                                  );

                                  // Convert the invoice date to a Date object
                                  const currentDate = new Date(); // Get the current date

                                  // Calculate the difference in time (in milliseconds)
                                  const timeDifference =
                                    currentDate - invoiceDate;

                                  // Check if the time difference is less than 24 hours (24 * 60 * 60 * 1000 milliseconds)
                                  if (timeDifference > 24 * 60 * 60 * 1000) {
                                    alert("Delete Need Permission");
                                    return;
                                  }

                                  if (
                                    window.confirm(
                                      "Do you want to update inventory by delete"
                                    )
                                  ) {
                                    stockHandler(item);
                                  }
                                  // deleteHandler(item);
                                }}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Select Patient</ModalHeader>
        <ModalBody>
          <Table color="secondary" bordered>
            <thead>
              <tr>
                <th>#</th>
                <th>Patient Name</th>
                <th>MRN</th>
                <th>Select</th>
              </tr>
            </thead>
            <tbody>
              {patientList &&
                patientList.map((item, id) => {
                  return (
                    <tr>
                      <td>{id + 1}</td>
                      <td>{`${item.fname} ${item.sname}`}</td>
                      <td>{item.mrn}</td>
                      <td>
                        <Input
                          type="radio"
                          onChange={() => {
                            setMrn(item.mrn);
                            toggle();
                          }}
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default SalesInvoices;
