import { useEffect, useState, React, lazy, Suspense } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import ReportChart from "../../../CRM-Calicut/Reports/ReportChart";
import "../../../../../Components/View/CRM/styles/style.css";
import * as XLSX from "xlsx";

import {
  getAppointments,
  getCustomers,
  getInvoices,
  getPharmacyInvoices,
  searchCustomers,
} from "../../../../../Data/Customer/api";
import { RefreshCcw } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import SalesReport from "./sales/SalesReport";
const DownloadX = lazy(() => import("./sales/DownloadX"));

const Reports = () => {
  const navigate = useNavigate();
  const itemsList = useSelector((state) => state.itemsList);
  const dispatch = useDispatch();
  const { supplierData, purchaseData, itemsData } = itemsList;
  const auth = useSelector((state) => state.authentication);
  const { userData } = auth;
  const [chartData, setChartData] = useState([]);
  const location = useLocation();
  const branchData = location.state;
  // State variables for filter options
  const [filterType, setFilterType] = useState("Select");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [spinnerId, setSpinnerId] = useState("");
  const [showBillLoading, setShowBillLoading] = useState(false);

  const [patientRegistrionData, setPatientRegistrionData] = useState("");
  const date = new Date();
  const datewithoutHours = new Date(date.setHours(0, 0, 0, 0)).toISOString();
  const startDay = new Date(date.setUTCHours(0, 0, 0, 0)).toISOString(); // searching purpose

  // const utcDate = selectedDate.toISOString(); // saving pupose
  let patientCount = 0;
  const branch = sessionStorage.getItem("branch")
    ? JSON.parse(sessionStorage.getItem("branch"))
    : "";
  const [todaysPurchaseInvoices, setTodaysPurchaseInvoices] = useState("");
  const [totalPurchaseInvoices, setTotalPurchaseInvoices] = useState("");

  const [todaysInvoices, setTodaysInvoices] = useState("");
  const [todaysTotalPharmacyInvoices, setTodaysTotalPharmacyInvoices] =
    useState("");
  const [totalConsultationPaid, setTotalConsultationPaid] = useState("");
  const [rotationAngle, setRotationAngle] = useState(0);
  const [loading, setLoading] = useState(true);
  const [isSpinning, setIsSpinning] = useState(false);
  const [customerDataDownload, setCustomerDataDownload] = useState("");
  const [selectedSupplier, setSelectedSupplier] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [filteredPurcahseInvoices, setFilteredPurcahseInvoices] = useState("");
  const [totalFilteredPurchaseInvoices, setTotalFilteredPurchaseInvoices] =
    useState("");
  const [totalExcelFilteredInvoices, setTotalExcelFilteredInvoices] =
    useState("");
  const [totalExcelDiscount, setTotalExcelDiscount] = useState("");
  const [excelFilteredPurchase, setExcelFilteredPurchase] = useState("");
  const [excelFilteredSales, setExcelFilteredSales] = useState("");
  const [fromDateError, setFromDateError] = useState("");
  const [toDateError, setToDateError] = useState("");
  const [dateError, setDateError] = useState("");
  const [excelTodaysPurchase, setExcelTodaysPurchase] = useState("");
  const [selectedItem, setSelectedItem] = useState("");
  const [invoicesData, setInvoicesData] = useState([]);
  // Validate date inputs
  const validateDate = (date) => {
    const pattern = /^\d{4}-\d{2}-\d{2}$/;
    return pattern.test(date);
  };

  const startAnimation = () => {
    setIsSpinning(true);
    setTimeout(() => setIsSpinning(false), 4000); // Stop animation after 4 seconds
  };

  // Create a new Date object for the current date and time in IST
  const currentDateIST = new Date();

  // Set the time components of the current date to represent the start of the day in IST
  currentDateIST.setHours(0, 0, 0, 0);

  // Get the ISO 8601 string representation of the start of the day in IST
  const startOfDayIST = currentDateIST.toISOString();

  const exportToExcel = (data) => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "my-file.xlsx");
  };

  const calculateTotal = (invoiceSheet) => {
    const amountValues = invoiceSheet
      .map((row, index) => Object.values(row)[index])
      .filter((i) => parseFloat(i));

    //  const qtyValues = invoiceSheet
    //    .map((row, index) => row[`Row${index + 1}`].qty)
    //    .filter((i) => parseFloat(i));
    //
    //  const taxValues = invoiceSheet
    //    .map((row, index) => row[`Row${index + 1}`].tax)
    //    .filter((i) => parseFloat(i));

    //  const taxableList = invoiceSheet
    //    .filter((row, index) => row[`Row${index + 1}`].tax)
    //    .map((row) => {
    //      const key = Object.keys(row)[0];
    //      // console.log(key, row[key]);
    //      return row[key];
    //      // return parseFloat(row[key].rate) * parseFloat(row[key].qty);
    //    });
    //  setTaxableData(taxableList);
    //     const totalTempTax = taxValues.reduce((acc, currentValue) => {
    //    const numericValue = parseFloat(currentValue);
    //    return acc + numericValue;
    //       }, 0);

    //  setTotalTax(totalTempTax.toFixed(2));
    //
    //  const totalTempQty = qtyValues.reduce((acc, currentValue) => {
    //    const numericValue = parseFloat(currentValue);
    //    return acc + numericValue;
    //  }, 0);

    //  setTotalQTY(totalTempQty);

    //const values = Object.values(inputValues).map((value) => parseFloat(value));
    const totalTempAmt = amountValues.reduce((acc, currentValue) => {
      const numericValue = parseFloat(currentValue);
      return acc + numericValue;
    }, 0);
    //console.log(total);
    //  setTotal(parseFloat(totalTempAmt).toFixed(2));
    return parseFloat(totalTempAmt).toFixed(2);
  };

  const getPharmacyInvoicesHandler = () => {
    getInvoices(userData.token, datewithoutHours, branch, "", "", navigate)
      .then((res) => {
        if (res.length) {
          const filterInvoices = res.map((invoice) => {
            // Filter items with productId and calculate total amount paid
            const itemsWithProductId = invoice.invoice_sheet.filter(
              (row) => row[Object.keys(row)].productId !== null
            );
            // Filter items without productId
            const itemsWithoutProductId = invoice.invoice_sheet.filter(
              (row) => row[Object.keys(row)].productId === null
            );
            console.log(itemsWithoutProductId);

            const totalAmountPaid = itemsWithProductId.reduce(
              (total, item) =>
                parseFloat(total) + parseFloat(item[Object.keys(item)].amount),
              0
            );
            const totalAmountPaidFormatted =
              typeof totalAmountPaid === "number"
                ? totalAmountPaid.toFixed(2)
                : totalAmountPaid;

            // Calculate total amount
            const totalAmount = itemsWithProductId.reduce(
              (total, item) =>
                parseFloat(total) + parseFloat(item[Object.keys(item)].amount),
              0
            );

            const totalAmountFormatted =
              typeof totalAmount === "number"
                ? totalAmount.toFixed(2)
                : totalAmount;
            // Return modified invoice object
            return {
              ...invoice,
              total_amount_paid: totalAmountPaidFormatted,
              total_amount: totalAmountFormatted,
              invoice_sheet: itemsWithProductId,
            };
          });

          setTodaysInvoices(filterInvoices);
          setTodaysTotalPharmacyInvoices(() => {
            const totalAmountPaidArray = filterInvoices.map(
              (item) => item.total_amount_paid
            );
            //  console.log(totalAmountPaidArray, filterInvoices);

            const totalAmountPaid = totalAmountPaidArray.reduce(
              (total, amount) => parseFloat(total) + parseFloat(amount),
              0
            );
            return totalAmountPaid;
          });
        }
      })
      .catch((err) => {
        console.log(err);
        if (
          (err.response &&
            err.response.data &&
            err.response.data.err.name === "TokenExpiredError") ||
          err.response.data.message === "Unauthorized"
        ) {
          navigate("/");
          sessionStorage.removeItem("token");
          sessionStorage.removeItem("srchData");
          sessionStorage.removeItem("prescriptionHistory");
          sessionStorage.removeItem("rights");
          navigate("/cri-crm");
          return err;
        }
        console.log(err);
        return err;
      });
  };

  const getPurchaseHandler = (dateArg) => {
    axios
      .get(`${process.env.REACT_APP_CRI_API}purchase`, {
        params: {
          branch: userData.branch,
          date: dateArg,
        },
        headers: { Authorization: `Bearer ${userData.token}` },
        "Content-Type": "application/json",
      })
      .then((res) => {
        // console.log(res);

        setTodaysPurchaseInvoices(() =>
          res.data.map((item) => {
            if (supplierData && item.supplier) {
              const supplier = supplierData.find(
                (sup) => sup.supplierId === item.supplier.supplierId
              );
              if (supplier && supplier) {
                return {
                  ...item,
                  supplier: { ...item.supplier, name: supplier.supplierName },
                };
              }
            }
            return item;
          })
        );

        if (res.data) {
          setTotalPurchaseInvoices(() => {
            const totalAmountPaidArray = res.data.map(
              (item) => item.paymentDetails && item.paymentDetails.amount_paid
            );
            const totalAmountPaid = totalAmountPaidArray.reduce(
              (total, amount) => parseFloat(total) + parseFloat(amount),
              0
            );
            return totalAmountPaid;
          });
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (userData.token && userData.token !== "") {
      // currently pharmacyinvoice  and invoices are merged
      getPharmacyInvoicesHandler(); // to do - avoid calling two times
      getPurchaseHandler(datewithoutHours);

      setSelectedDate(new Date());
    }

    if (!userData.token) {
      navigate("/");
    }
    // console.log(branchData);
  }, []);

  //  useEffect(() => {
  //    if (purchaseData) {
  //    }
  //  }, [purchaseData]);

  // format date to show
  const formatDate = (date) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Intl.DateTimeFormat("en-US", options).format(date);
  };

  const validate = () => {
    let isValid = true;

    if (!validateDate(fromDate) && !validateDate(selectedDate)) {
      setFromDateError("Please enter a valid From Date (YYYY-MM-DD).");

      isValid = false;
    } else {
      setFromDateError("");
      setDateError("");
    }

    if (!validateDate(toDate) && !validateDate(selectedDate)) {
      setToDateError("Please enter a valid To Date (YYYY-MM-DD).");

      isValid = false;
    } else {
      setToDateError("");
    }
    return isValid;
  };
  const clearError = () => {
    setToDateError("");
    setFromDateError("");
    setDateError("");
  };
  const filterHandler = () => {
    setTotalFilteredPurchaseInvoices("");
    setFilteredPurcahseInvoices("");
    if (validate()) {
      let newSelectedDate;
      let date;
      let fromDateWithoutHours;
      let toDateWithoutHours;
      if (selectedDate) {
        newSelectedDate = new Date(selectedDate);
        date = new Date(newSelectedDate.setHours(0, 0, 0, 0)).toISOString();
        sessionStorage.setItem("selectedDate", JSON.stringify(selectedDate));
      }

      if (fromDate) {
        newSelectedDate = new Date(fromDate);
        fromDateWithoutHours = new Date(
          newSelectedDate.setHours(0, 0, 0, 0)
        ).toISOString();
      }
      if (toDate) {
        newSelectedDate = new Date(toDate);
        toDateWithoutHours = new Date(
          newSelectedDate.setHours(0, 0, 0, 0)
        ).toISOString();
      }

      axios
        .get(`${process.env.REACT_APP_CRI_API}purchase`, {
          params: {
            branch: userData.branch,
            date: date,
            supplierId: selectedSupplier,
            fromDate: fromDateWithoutHours,
            toDate: toDateWithoutHours,
          },
          headers: { Authorization: `Bearer ${userData.token}` },
          "Content-Type": "application/json",
        })
        .then((res) => {
          if (selectedItem) {
            const filterPharmacyInvoices = res.data.map((invoice) => {
              // Filter items with productId and calculate total amount paid
              const itemsWithProductId = invoice.invoice.filter(
                (row) => row[Object.keys(row)].item === selectedItem
              );

              const totalAmountPaid = itemsWithProductId.reduce(
                (total, item) =>
                  parseFloat(total) +
                  parseFloat(item[Object.keys(item)].amount),
                0
              );
              const totalAmountPaidFormatted =
                typeof totalAmountPaid === "number"
                  ? totalAmountPaid.toFixed(2)
                  : totalAmountPaid;
              // Calculate total amount Pharmacy
              const totalAmount = itemsWithProductId.reduce(
                (total, item) => total + item[Object.keys(item)].amount,
                0
              );
              const totalAmountFormatted =
                typeof totalAmount === "number"
                  ? totalAmount.toFixed(2)
                  : totalAmount;
              const pharmacyInvoice = {
                ...invoice,
                total_amount_paid: totalAmountPaidFormatted,
                total_amount: totalAmountFormatted,
                invoice: itemsWithProductId,
              };

              return pharmacyInvoice;
            });
            if (filterPharmacyInvoices && filterPharmacyInvoices.length > 0) {
              setFilteredPurcahseInvoices(() =>
                filterPharmacyInvoices.filter((item) => item.invoice.length > 0)
              );

              setTotalFilteredPurchaseInvoices(() => {
                const totalAmountPaidArray = filterPharmacyInvoices
                  .map((item) => item.total_amount_paid)
                  .filter((value) => !isNaN(value));

                const totalAmountPaid = totalAmountPaidArray.reduce(
                  (total, amount) => parseFloat(total) + parseFloat(amount),
                  0
                );
                // console.log(filterInvoices[0]?.pharmacy);
                return totalAmountPaid;
              });
            }
            return;
          }
          setFilteredPurcahseInvoices(() => {
            return res.data.map((item) => {
              if (supplierData && item.supplier) {
                const supplier = supplierData.find(
                  (sup) => sup.supplierId === item.supplier.supplierId
                );
                if (supplier && supplier) {
                  return {
                    ...item,
                    supplier: { ...item.supplier, name: supplier.supplierName },
                  };
                }
              }
              return item;
            });
          });
          if (res.data) {
            setTotalFilteredPurchaseInvoices(() => {
              const totalAmountPaidArray = res.data.map(
                (item) => item.paymentDetails && item.paymentDetails.amount_paid
              );
              const totalAmountPaid = totalAmountPaidArray.reduce(
                (total, amount) => parseFloat(total) + parseFloat(amount),
                0
              );

              return totalAmountPaid;
            });
          }
        });
    }
  };

  useEffect(() => {
    if (filteredPurcahseInvoices.length) {
      const data = filteredPurcahseInvoices;

      setExcelFilteredPurchase(() =>
        data.map((item) => ({
          supplierId: item.supplier.supplierId,
          supplierName:
            supplierData.find(
              (sup) => sup.supplierId === item.supplier.supplierId
            )?.supplierName || "",
          invoice_date: formatDate(new Date(item.paymentDetails.invoice_date)),
          invoice_id: item.paymentDetails.invoice_id,
          amount_paid: item.paymentDetails.amount_paid,
          payment_mode: item.paymentDetails.payment_mode,
          items: Array.isArray(item.invoice)
            ? item.invoice
                .map((obj) => {
                  //   if (obj.invoice_id === item.paymentDetails.invoice_id)
                  return {
                    item: obj.item,
                    mrp: obj.mrp,
                    quantity: obj.quantity,
                  };
                })
                .filter((item) => item)
                .map((x) => `${x.item} - Amount: ${x.mrp}, Qty: ${x.quantity}`)
                .join(", ")
            : [],
        }))
      );
    }
  }, [filteredPurcahseInvoices]);

  useEffect(() => {
    if (todaysPurchaseInvoices) {
      setExcelTodaysPurchase(() =>
        todaysPurchaseInvoices.map((prch, id) => {
          return {
            Sl_No: id + 1,
            Invoice_Date: formatDate(
              new Date(prch.paymentDetails.invoice_date)
            ),
            Supplier_Name:
              (supplierData &&
                supplierData.find(
                  (sup) => sup.supplierId === prch.supplier.supplierId
                )?.supplierName) ||
              "",
            Payment_Mode: prch.paymentDetails.payment_mode,
            Invoice_ID: prch.paymentDetails.invoice_id,
            Amount_Paid: prch.paymentDetails.amount_paid,
          };
        })
      );
    }
  }, [todaysPurchaseInvoices]);

  function formatItemDetails(sale) {
    // console.log(sale.invoice_sheet);
    if (sale.invoice_sheet)
      return sale.invoice_sheet
        .map((row, index) => {
          const rowData = Object.values(row)[0];
          const itemDetails = Object.entries(rowData)
            .filter(
              ([key, value]) =>
                value && key !== "productId" && key !== "stockQty"
            )
            .map(([key, value]) => {
              if (
                key === "qty" ||
                key === "rate" ||
                key === "tax" ||
                key === "cgst" ||
                key === "sgst"
              ) {
                return `${key}: ${value}`;
              } else {
                return value;
              }
            })
            .join(", ");

          return itemDetails;
        })
        .join("\n");
  }
  const searchCustomerHandler = (invoice, mode) => {
    // searchCustomerHandler(item, "Return Mode");
    //setShowBillLoading(true);
    //token, searchInput, branch, navigate
    searchCustomers(
      userData.token,
      invoice.mrn,
      userData.branch,
      navigate
    ).then((res) => {
      if (res.length) {
        const [
          { fname, sname = "", sex = "", contactno, nationality, doctorName },
        ] = res;

        navigate("/billentry", {
          //replace: true,
          state: {
            otData: {
              ...invoice,
              fname: `${fname} ${sname}`,
              sex: sex,
              contactno: contactno,
              nationality,
              doctorName,
            },
            invoiceSheetData:
              invoicesData.length &&
              invoicesData.find(
                (item) => item.invoice_id === invoice.invoice_id
              ),
            mode,
          },
        });
        setSpinnerId("");
        setShowBillLoading(false);
      }
    });
  };

  return (
    <Container className="pb-5">
      <Row>
        <Col className="d-flex justify-content-center ">
          {" "}
          <h4>Sales Report</h4>
        </Col>
      </Row>
      <Row>
        <Col md="1" className="pb-4">
          <button onClick={() => navigate(-1)}>Back</button>
        </Col>
      </Row>

      <Row>
        <Col className="text-center" md={6}>
          <Card className="mt-3 pt-3">
            <CardTitle>
              <b>Today's Sales</b>
            </CardTitle>
            <CardBody>
              <ReportChart
                data={todaysInvoices}
                chartName="Today's Patient Invoice"
                total={todaysTotalPharmacyInvoices}
                selectedDate={selectedDate}
              />
            </CardBody>
          </Card>
        </Col>
        <Col className="text-center" md={6}>
          <Card className="mt-3 pt-3">
            <CardTitle>
              <b>Today's Purchase</b>
            </CardTitle>
            <CardBody>
              <ReportChart
                data={todaysPurchaseInvoices}
                chartName="Today's Purchase"
                total={totalPurchaseInvoices}
                selectedDate={selectedDate}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col className="text-center" md={12}>
          <Card className="mt-3 pt-3">
            <CardTitle>
              <b>Purchase Report</b>
              <div className="d-flex">
                {" "}
                <FormGroup className="me-3 ps-2">
                  <Label for="filterType">Filter Type:</Label>
                  <Input
                    type="select"
                    name="filterType"
                    id="filterType"
                    value={filterType}
                    onChange={(e) => setFilterType(e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="yearly">Yearly</option>
                    <option value="monthly">Monthly</option>
                    <option value="date">Date</option>
                  </Input>
                </FormGroup>
                {filterType === "date" && (
                  <FormGroup>
                    <Label for="selectedDate">Select Date:</Label>
                    <Input
                      type="date"
                      name="selectedDate"
                      id="selectedDate"
                      value={selectedDate}
                      onChange={(e) => {
                        clearError();
                        setSelectedDate(e.target.value);
                      }}
                      invalid={dateError !== ""}
                    />
                  </FormGroup>
                )}
                {filterType !== "date" && (
                  <>
                    <FormGroup>
                      <Label for="fromDate">From Date:</Label>
                      <Input
                        type="date"
                        name="fromDate"
                        id="fromDate"
                        value={fromDate}
                        onChange={(e) => {
                          setFromDate(e.target.value);
                          clearError();
                        }}
                        invalid={fromDateError !== ""}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="toDate">To Date:</Label>
                      <Input
                        type="date"
                        name="toDate"
                        id="toDate"
                        value={toDate}
                        onChange={(e) => {
                          setToDate(e.target.value);
                          clearError();
                        }}
                        invalid={toDateError !== ""}
                      />
                    </FormGroup>
                  </>
                )}
                <FormGroup>
                  <Label for="supplier">Supplier:</Label>
                  <Input
                    type="select"
                    name="supplier"
                    id="supplier"
                    value={selectedSupplier}
                    onChange={(e) => {
                      setSelectedSupplier(e.target.value);
                      clearError();
                    }}
                  >
                    <option value="">Select Supplier</option>
                    {supplierData.length &&
                      supplierData.map((supplier) => (
                        <option
                          key={supplier.supplierId}
                          value={supplier.supplierId}
                        >
                          {supplier.supplierName}
                        </option>
                      ))}
                  </Input>
                </FormGroup>
                {
                  <FormGroup>
                    <Label for="item">Item:</Label>
                    <Input
                      type="select"
                      name="item"
                      id="item"
                      value={selectedItem}
                      onChange={(e) => {
                        setSelectedItem(e.target.value);
                        clearError();
                      }}
                    >
                      <option value="">Select Item</option>
                      {itemsData.length &&
                        itemsData.map((item) => (
                          <option key={item.productId} value={item.item}>
                            {item.item}
                          </option>
                        ))}
                    </Input>
                  </FormGroup>
                }
                <button
                  size="sm"
                  onClick={filterHandler}
                  className="ms-3"
                  style={{
                    width: "auto",
                    height: "40px",
                    borderRadius: "10px",
                    marginTop: "30px",
                  }}
                >
                  Search
                </button>
              </div>
            </CardTitle>
            <CardBody>
              <ReportChart
                data={filteredPurcahseInvoices}
                chartName="Today's Purchase"
                total={totalFilteredPurchaseInvoices}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      <SalesReport
        supplierData={supplierData}
        setExcelFilteredSales={setExcelFilteredSales}
        setTotalExcelFilteredInvoices={setTotalExcelFilteredInvoices}
        setTotalExcelDiscount={setTotalExcelDiscount}
        setInvoicesData={setInvoicesData}
      />
      <Row className="d-flex justify-content-center pt-3">
        <Col sm={4} style={{ textAlign: "center" }}>
          <h5>Purchase Report </h5>
        </Col>
        <Col sm={1}>
          {
            //    <RefreshCcw style={{ cursor: "pointer" }} color="green" />
          }
        </Col>
      </Row>
      <Table striped>
        <thead>
          <tr>
            <th>Sl.No</th>
            <th>Invoice Date</th>
            <th>Supplier</th>
            <th>Payment Mode</th>
            <th>Invoice ID</th>
            <th>Total Amount</th>
          </tr>
        </thead>
        <tbody>
          {excelFilteredPurchase &&
            excelFilteredPurchase.map((prchse, id) => {
              return (
                <tr key={id}>
                  <td>{id + 1}</td>
                  <td>{formatDate(new Date(prchse.invoice_date))}</td>
                  <td>{prchse.supplierName}</td>
                  <td>{prchse.payment_mode}</td>
                  <td>{prchse.invoice_id}</td>
                  <td>{prchse.amount_paid}</td>
                </tr>
              );
            })}
          <tr>
            <td colSpan={5} style={{ textAlign: "right" }}>
              {" "}
              <b>Total</b>
            </td>
            <td>
              <b>{totalFilteredPurchaseInvoices}</b>{" "}
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td
              colSpan={6}
              style={{
                backgroundColor: "transparent",

                textAlign: "end",
              }}
            >
              {filteredPurcahseInvoices.length > 0 && (
                <Suspense fallback={<span>Loading...</span>}>
                  <DownloadX
                    invoicesData={filteredPurcahseInvoices}
                    exportToExcel={exportToExcel}
                  />
                </Suspense>
              )}
            </td>
          </tr>
        </tfoot>
      </Table>
      <Row className="d-flex justify-content-center pt-3">
        <Col sm={4} style={{ textAlign: "center" }}>
          <h5>Today's Purchase</h5>
        </Col>
        <Col sm={1}>
          <RefreshCcw
            style={{ cursor: "pointer" }}
            color="green"
            onClick={getPurchaseHandler}
          />
        </Col>
      </Row>
      <Table striped>
        <thead>
          <tr>
            <th>Sl.No</th>
            <th>Invoice Date</th>
            <th>Supplier</th>
            <th>Payment Mode</th>
            <th>Invoice ID</th>
            <th>Total Amount</th>
          </tr>
        </thead>
        <tbody>
          {todaysPurchaseInvoices &&
            todaysPurchaseInvoices.map((prch, id) => {
              return (
                <tr key={id}>
                  <td>{id + 1}</td>
                  <td>
                    {formatDate(new Date(prch.paymentDetails.invoice_date))}
                  </td>
                  <td>
                    {(supplierData &&
                      supplierData.find(
                        (sup) => sup.supplierId === prch.supplier.supplierId
                      )?.supplierName) ||
                      ""}
                  </td>
                  <td>{prch.paymentDetails.payment_mode}</td>
                  <td>{prch.paymentDetails.invoice_id}</td>
                  <td>{prch.paymentDetails.amount_paid}</td>
                </tr>
              );
            })}
          <tr>
            <td colSpan={5} style={{ textAlign: "right" }}>
              {" "}
              <b>Total</b>
            </td>
            <td>
              <b>{totalPurchaseInvoices}</b>{" "}
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td
              colSpan={6}
              style={{
                backgroundColor: "transparent",

                textAlign: "end",
              }}
            >
              {todaysPurchaseInvoices.length > 0 && (
                <Suspense fallback={<span>Loading...</span>}>
                  <DownloadX
                    invoicesData={todaysPurchaseInvoices}
                    exportToExcel={exportToExcel}
                  />
                </Suspense>
              )}
            </td>
          </tr>
        </tfoot>
      </Table>
      <Row className="d-flex justify-content-center pt-3">
        <Col sm={4} style={{ textAlign: "center" }}>
          <h5>Sales Report</h5>
        </Col>
        <Col sm={1}>
          {
            //  <RefreshCcw
            //    style={{ cursor: "pointer" }}
            //    color="green"
            //    onClick={getPurchaseHandler}
            //  />
          }
        </Col>
      </Row>
      <Table striped>
        <thead>
          <tr>
            <th>Sl.No</th>
            <th>Invoice Date</th>
            <th>Invoice ID</th>
            <th>Amount</th>
            <th>Payment Mode</th>
            <th>Amount Paid </th>
            <th>Discount </th>
          </tr>
        </thead>
        <tbody>
          {excelFilteredSales &&
            excelFilteredSales.map((sale, id) => {
              return (
                <tr key={id}>
                  <td>{id + 1}</td>
                  <td>{sale.invoice_date}</td>
                  <td
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setSpinnerId(id);
                      searchCustomerHandler(sale);
                      console.log(sale);
                    }}
                  >
                    {showBillLoading && spinnerId === id && <Spinner />}
                    {sale.invoice_id}
                  </td>
                  <td>{sale.amount}</td>
                  {
                    // <td>{sale.items}</td>
                  }
                  <td>{sale.payment_mode}</td>

                  <td>{sale.amount_paid}</td>
                  <td>{sale.discount}</td>
                </tr>
              );
            })}
          <tr>
            <td colSpan={5} style={{ textAlign: "right" }}>
              {" "}
              <b>Total</b>
            </td>
            <td>
              <b>{totalExcelFilteredInvoices}</b>{" "}
            </td>
            <td>
              <b>{totalExcelDiscount}</b>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td
              colSpan={7}
              style={{
                backgroundColor: "transparent",
                textAlign: "end",
              }}
            >
              <div>
                {excelFilteredSales.length > 0 && (
                  <Suspense fallback={<span>Loading...</span>}>
                    <DownloadX
                      invoicesData={excelFilteredSales}
                      exportToExcel={exportToExcel}
                    />
                  </Suspense>
                )}
              </div>
            </td>
          </tr>
        </tfoot>
      </Table>
    </Container>
  );
};

export default Reports;
