import { Lock } from "react-feather";
import { Link } from "react-router-dom";
import { Card, CardBody, CardTitle } from "reactstrap";

const Unauthorized = () => {
  
  return (
    <div className="d-flex justify-content-center mt-5 mb-auto">
      <Card className="mt-5 p-5">
        <CardTitle>
          <h1> Unauthorized </h1>
        </CardTitle>
        <CardBody>
          <div style={{ textAlign: "center" }}>
            <Lock color="red" size={50} />
          </div>
          <div style={{ textAlign: "center" }}>
            <Link to="/cri-crm">Try login again</Link>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default Unauthorized;
